.content-heading{
    background: #fff;
    box-shadow: -5px 5px 15px rgba(0,0,0,.1);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    align-content: center;
    padding: 15px;
    margin: 0 -15% 0 15%;
    z-index: 9;
    position: relative;
}
.elementor-video{
    max-width: 100%;
    width: 100%;
    margin: 0;
    line-height: 1;
    border: none;
}
.exp-bg-1{
    position: relative;
    background: url('../../../../public/assets/img/exp-1.jpg') no-repeat;
    background-size: contain;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
}
.exp-bg-1::before{
    content: '';
    background-color: rgba(0,0,0,.8);
    position: absolute;
    width: 100%;
    height: 100%;
    top:0;
    z-index: 2;
}

.flex-center{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-top: 150px;
    padding-bottom: 120px;
    text-align: center;
}
.exp-title{
    color:#FFF;
    font-size: 1.5rem;
    position: relative;
    z-index: 3;
    text-transform: uppercase;
    font-weight: 600;
    letter-spacing: 1px;
    margin-bottom: 10px;
}
.exp-description{
    color:#fff;
    position: relative;
    font-size:1rem;
    z-index: 3;
}
.exp-description p{
    letter-spacing: 1px;
    padding:3px 0;
}
.exp-description a{
    display: inline-block;
    margin:10px 0;
    position: relative;
    padding: 8px 20px;
    color: #FFF;
    text-transform: uppercase;
    font-family: 'Montserrat', sans-serif;
    font-size: 1rem;
    font-style: normal;
    font-weight: 600;
    z-index: 5;
    border: 1px solid rgba(255, 255, 255, 1);
    background-color: #688B4A;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2);
    border-radius: 40px;
    cursor: pointer;
    text-decoration: none;
}
.exp-description a:hover{
    background-color: #FFFFFF;
    border:1px solid #688B4A;
    color: #688B4A;
}