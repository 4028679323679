.footer{
    margin-bottom: 100px;
}
.footer p{
    padding:0 0 20px 0;
    margin:0;
    text-align: center;
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 22px;
    letter-spacing: 0.02em;
    color: #37562F;
}