.wrapper{
    background-color: #37562F;
    width: 100%;
    height: 75px;
    display: flex;
    align-items: center;
}
.wrapper.fixo{
    width: 100%;
    position: fixed;
    bottom:0;
    z-index: 10;
}
.form_search{
    display: flex;
    justify-content: space-between;
}
.form_search label{
    position: relative;
    /* margin-right: 30px; */
}
.form_search
.input_text_date{
    background-color: transparent;
    border:0;
    border-bottom: 1px solid #FFF;
    padding:10px;
    outline: 0;
    cursor: pointer;
    color:#FFF;
    font-weight: 700;
    text-align: center;
    width: 175px;
}
.form_search img{
    position: absolute;
    right: 0;
    bottom: 15px;
}
.form_search
.input_text_date::placeholder{
    color:#FFF;
    font-weight: 600;
    line-height:normal;
}
.form_search
input[type=date]{
    color: #fff;
    font-weight: 600;
    line-height:normal;
}

.form_search
.select_text{
    background-color: transparent;
    border:0;
    border-bottom: 1px solid #FFF;
    padding:10px 0;
    outline: 0;
    cursor: pointer;
    color:#FFF;
    font-weight: 700;
    text-align: center;
    width: 175px;
}

.btn{
    border:0;
    cursor:pointer;
    border-radius:4px;
    box-sizing: border-box;
    padding:10px 20px;
}
.btn_success{
    font-family: 'Montserrat', sans-serif;
    text-transform: uppercase;
    border: 1px solid #688B4A;
    background-color: #688B4A;
    color:#FFF;
    font-size:0.8rem;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
        
}
.btn_success:hover{
    opacity:0.9;
}