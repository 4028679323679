.menu{
    /* width: 80%; */
    /* flex: 0 0 80%; */
    flex:auto;
    z-index: 2;
}
.menu ul{
    list-style: none;
    display: flex;
    flex-direction: row;
    flex-flow: row wrap;
    justify-content: flex-start;
    height: 100%;
    align-items: center;
}
.menu li{
    margin: 0 20px;
}
.menu a{
    color:#FFF;
    text-decoration: none;
    font-family: 'Montserrat', sans-serif;
    font-weight: 600;
    text-transform: uppercase;
    font-size: 0.875rem;
}
.menu a:hover{
    text-decoration: underline;
}
.menu-extra{
    display: flex;
    flex-direction: row;
    flex-flow: row wrap;

    /* margin-left:20px; */
}
.menu-translate{
    margin:0 20px;
}
.menu-translate a{
    background-color: #FFF;
    font-size:11px;
    font-weight: 600;
    color:#111;
    border-radius: 4px;
    padding:10px;
    position: relative;
}
.menu-translate a:hover{
    text-decoration: none;
}
.menu-translate a img{
    position: relative;
    animation:none;
    width: 15px;
    height: 15px;
    z-index: 2;
    vertical-align: middle;
    margin-right: 10px;
}
.menu-social{
   
    vertical-align: middle;
}
.menu-social a{
    margin:0 10px;
}
.menu-social img{
    width: 25px;
    height: 25px;
    position: relative;
    right: 0;
    animation:none;

}