@media only screen and (max-width: 970px){
    .menu-burger{
        display: block;
    }
    .none{
        display: none !important;
    }
    .menu-responsivo{
        display: block;
        background-color: rgba(0, 14, 6, 1);
        position: fixed;
        top: 0;
        left:0;
        width: 100%;
        height: 100%;
        z-index: 999;
    }
    .menu-responsivo ul{
        list-style: none;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-align: center;
        
    }
    .menu-responsivo li{
        flex:auto;
        width: 100%;
        border-bottom: 1px solid #ffffff10;
    }
    .menu-responsivo li:last-child{
        border-bottom:0;
    }
    .menu-responsivo a{
        color:#FFF;
        text-decoration: none;
        font-size: 1rem;
        display: flex;
        flex-direction: row wrap;
        justify-content: center;
        align-items: center;
        padding:10px 0;
        text-transform: uppercase;
        transition: all 0.5s;
    }
    .menu-responsivo .logo a:hover{
        background-color: transparent;
    }
    .menu-responsivo a:hover{
        /* background-color: #638547; */
    }
    .menu-responsivo span{
        display: block;
        font-size: 0.7rem;
        font-weight: 600;
    }
}