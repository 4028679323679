.wrapper-flex{
    display: flex;
    flex-direction: row;
    flex-flow: row wrap;
    margin: 60px 0 0 0;
}
.item{
    flex:auto;
    width: 50%;
    height: auto;
}
.item:first-child{
    background-color: transparent;
    z-index: 5;
}
.item:last-child{
    z-index: 6;
}
.item-images{
    position: relative;
    object-fit: cover;
    overflow: hidden;
   
}
.item-images img{
    width: 100%;
    z-index: -2;
    position: relative;
}
.item-text{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: transparent;
}
.item-title{
    font-family: 'Montserrat', sans-serif;
    font-size:1.5rem;
    text-transform: uppercase;
    color:#FFF;
    font-style: normal;
    font-weight: bold;
    max-width: 400px;
    width: 50%;
    text-align: center;
}
.item-btn{
    margin-top:20px;
}
.item-btn a{
    position: relative;
    padding:8px 20px;
    color:#FFF;
    text-transform: uppercase;
    font-family: 'Montserrat', sans-serif;
    font-size: 1rem;
    font-style: normal;
    font-weight: 600;
    z-index: 5;
    border:1px solid rgba(255, 255, 255, 1);
    background-color: #688B4A;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2);
    border-radius: 40px;
    cursor: pointer;
    outline: none;
    text-decoration: none;
}
.item-btn a:hover{
    background-color: #FFFFFF;
    border:1px solid #688B4A;
    color: #688B4A;
}
.item:last-child{
    /* background-color: red; */
    display: flex;
    justify-content: center;
    align-items: center;
}
.item-cards{
    display: flex;
    flex-direction: row;
    flex-flow: row wrap;
    justify-content: flex-start;
    /* background-color: yellow; */
    width: 100%;
    height: auto;
    position: relative;

   
}
.item-card-box{
    max-width: 366px;
    width: 50%;
    height: 438px;
    background-color: #FFFFFF;
   
}
.item-card-box:first-child{
    position: relative;
    margin-left:-40px;
}
.item-card-box:last-child{
    margin-left: 3%;
}
.item-card-box{
    background: #FFFFFF;
    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.1);
    border-radius: 2px;
    text-align: center;
    position: relative;
}
.item-card-image img{
    width: 100%;
    height: auto;
}
.item-card-title{
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 200%;
    text-align: center;
    letter-spacing: 0.02em;
    color: #434343;
    padding:5px 20px;
}
.item-card-description{
    font-style: normal;
    font-weight: 300;
    font-size: 16px;
    line-height: 145%;
    text-align: justify;
    color: #000000;
    padding:5px 25px;
}
.item-card-description strong{
    font-weight: 600;
    color: #434343;
    font-style: normal;
}
.item-card-box .btn-link{
    display: block;
    width: 149px;
    /* height: 40px; */
    margin: auto;
    background-color: #688B4A;
    border-radius: 40px;
    text-decoration: none;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 19px;
    color: #FFFFFF;
    padding:8px 20px;
    text-transform: uppercase;
    position: absolute;
    bottom: 20px;
    left: -50%;
    right: -50%;
}
.item-card-box .btn-link:hover{
    border:1px solid #688B4A;
    background-color: #FFFFFF;
    color: #688B4A;
}