.page-section-wrapper{
    max-width: 600px;
    width: 100%;
    margin:auto;
}
.section-wrapper-item ul{
    list-style: none;
    max-width: 200px;
    width: 100%;
    margin:auto;
}

.section-wrapper-item li{
    margin-bottom: 20px;
    text-align: left;
}
.section-wrapper-item li img{
    margin-right: 10px;
    vertical-align: middle;
}
.section-wrapper-item-image img{
    width: 100%;
}
.section-wrapper-item-image{
    width: 100%;
    height: 450px;
    overflow: hidden;
}
.page-section-container{
    max-width: 850px;
    margin:auto;
    margin-bottom: 40px;
}