.header{
    width: 100%;
    height: calc(100vh - 75px);
    overflow: hidden;
    /* background-color: rgba(0, 0, 0, 0.4); */
    position: relative;
    z-index: 999;
}

.header img{
    position: absolute;
    width: 100%;
    height: calc(100vh - 75px);
    top:0;
    left:0;
    z-index: -1;
    animation: zoom 20s;
    object-fit: cover;
    /* animation-duration: 5s; */
    animation-name: zoom;
    animation-iteration-count: infinite;
    animation-direction: alternate;
}
.header_top{
    width: 100%;
    display: flex;
    background: rgb(11,15,10);
    background: linear-gradient(180deg, rgba(11,15,10,0.8589636538209033) 0%, rgba(0,0,0,0.08585441012342432) 100%);
    flex-direction: row;
    justify-content: space-between;
    position: relative;
}
.header_top.fixo{
    width: 100%;
    height: 90px;
    background: rgb(40,52,37);
    background: linear-gradient(90deg, rgba(40,52,37,1) 0%, rgba(55,86,47,1) 100%);
    position: fixed;
    top:0;
}
.header_top.fixo .logo img{
    width: 100px;
    height: 100px;
    animation:none;
}
.header_top.bg{
    background-color: #31492a;
}

/* .header img{
    width: 100%;
    height: 100%;
    object-fit: cover;
    opacity: 0.5;
    animation: zoom 25s;
} */

@keyframes zoom {
    0%{
        transform: scale(1);
    }
    100%{
        transform: scale(1.5);
    }
 }
.menu-burger{
    position: absolute;
    right: 10px;
    top:20px;
    z-index: 9999;
    color:#FFF;
}



.page_header{
    width: 100%;
    position: relative;
    z-index: 999;
}
.page_header_top{
    width: 100%;
    height: 90px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    background: rgb(40,52,37);
    background: linear-gradient(90deg, rgba(40,52,37,1) 0%, rgba(55,86,47,1) 100%);
    top:0;
}
.page_header_top .logo img{
    width: 100px;
    height: 100px;
    animation:none;
}
.page_section{
    position: relative;
    padding-top: 80px;
    padding-bottom: 10px;
    color:#000;
    font-size: 1rem;
    text-align: center;
}
.page_title{
    font-size: 1.5rem;
    font-weight: 600;
    letter-spacing: 1px;
    margin-bottom: 10px;
    color: #37562F;
    font-style: normal;
    font-weight: bold;
}
.page_description{
    max-width: 800px;
    width: 100%;
    margin:auto;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 145%;
    letter-spacing: 0.02em;
    padding: 0 40px;
    color: #434343;
}
.page_description_1{
    max-width: 1100px;
    width: 100%;
    margin:auto;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 145%;
    letter-spacing: 0.02em;
    padding: 0 40px;
    color: #434343;
}